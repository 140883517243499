<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<h3 class="h3">帳號設定</h3>
		</div>

		<table id="data-table" class="table table-borderless">
			<tbody>
				<tr>
					<th>帳號</th>
					<td>{{ userData.account }}</td>
				</tr>
				<tr>
					<th>名稱</th>
					<td>{{ userData.name }}</td>
				</tr>
				<tr>
					<th>角色</th>
					<td>{{ userData.roleName }}</td>
				</tr>
				<tr>
					<th>備註</th>
					<td class="white-space-pre-wrap">{{ userData.note }}</td>
				</tr>
			</tbody>
		</table>

		<div class="d-flex justify-content-center align-items-center">
			<button class="btn btn-info" @click="edit.showModal">編輯</button>
		</div>
	</div>

	<div id="editModal" class="modal fade" tabindex="-1">
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">編輯帳號</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<Vueform
						ref="editForm$"
						:schema="edit.formSchema"
						:display-errors="false"
						validate-on="step"
					></Vueform>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
					<button type="button" class="btn btn-primary" @click="edit.submit()">確定</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { useStore } from "vuex";
import { ref, reactive, computed, onMounted } from "vue";
import { Modal } from "bootstrap";
import { apiGetRoleList, apiUpdateLoginUser } from "@/assets/js/api.js";
export default {
	name: "UserProfile",
	setup() {
		const store = useStore();

		const editForm$ = ref(null);
		const edit = reactive({
			modal: {},
			formSchema: {
				password: {
					label: "舊密碼",
					type: "text",
					inputType: "password",
					default: "",
					rules: ["required", "min:4", "max:20"],
				},
				new_password: {
					label: "密碼",
					type: "text",
					inputType: "password",
					default: "",
					rules: ["required", "min:4", "max:20"],
				},
				new_password_confirmation: {
					label: "確認密碼",
					type: "text",
					inputType: "password",
					default: "",
					rules: ["required", "min:4", "max:20"],
				},
			},
			showModal() {
				editForm$.value.reset();

				setTimeout(() => {
					editForm$.value.resetValidators();
					edit.modal.show();
				}, 100);
			},
			submit() {
				const data = editForm$.value.data;
				editForm$.value.validate().then(() => {
					if (!editForm$.value.invalid) {
						setPageLoading(true);

						apiUpdateLoginUser(data)
							.then((response) => {
								setPageLoading(false);

								if (response.data.status == "0") {
									Swal.fire({
										icon: "success",
										text: response.data.message,
									});

									getUser();
									edit.modal.hide();
								} else {
									Swal.fire({
										icon: "warning",
										text: response.data.message,
									});
								}
							})
							.catch((error) => {
								setPageLoading(false);
								console.error(error);
								Swal.fire({
									icon: "error",
									text: "CODE ERROR",
								});
							});
					}
				});
			},
		});

		const userData = computed(() => store.state.userData);

		const getUser = () => store.dispatch("getUser");
		const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

		const getRoleList = () => {
			setPageLoading(true);

			apiGetRoleList()
				.then((response) => {
					setPageLoading(false);
					if (response.data.status == "0") {
						edit.formSchema.role_id.items = response.data.data.role_list;
					} else {
						Swal.fire({
							icon: "warning",
							text: response.data.message,
						});
					}
				})
				.catch(() => {
					setPageLoading(false);
				});
		};

		onMounted(() => {
			edit.modal = new Modal(document.getElementById("editModal"));
			getUser();
			getRoleList();
		});

		return {
			editForm$,
			edit,
			userData,
		};
	},
};
</script>

<style lang="scss" scoped>
#data-table {
	width: 500px;

	th {
		width: 60px;
	}
}
</style>
